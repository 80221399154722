import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const Caption = makeShortcode("Caption");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "overflow-content-is-text-such-as-a-paragraph-or-a-text-string-that-exceeds-a-desired-space-it-also-applies-to-a-series-of-components-that-surpass-a-given-space-overflow-content-is-typically-reduced-to-fit-a-space-or-reduce-repetition-truncation-and-show-more-buttons-are-two-ways-to-indicate-that-overflow-content-is-continued-elsewhere-or-below-the-fold"
    }}><em parentName="h3">{`Overflow content`}</em>{` is text, such as a paragraph or a text string, that exceeds a desired space. It also applies to a series of components that surpass a given space. Overflow content is typically reduced to fit a space or reduce repetition. Truncation and ‘Show more’ buttons are two ways to indicate that overflow content is continued elsewhere or below the fold.`}</h3>
    <h2 {...{
      "id": "truncation"
    }}>{`Truncation`}</h2>
    <AnchorLinks small mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Usage</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Variations</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Code</AnchorLink>
      <AnchorLink mdxType="AnchorLink">‘Show more’ buttons</AnchorLink>
    </AnchorLinks>
    <p>{`Truncation, or shortening, is typically used for static text or links that exceed the size of their container. Truncated items are represented by an ellipsis `}<inlineCode parentName="p">{`...`}</inlineCode>{` and should represent three or more truncated characters in a text string. There must be at least four characters of non-truncated content in a truncated string. Truncated items always include a browser tooltip on hover to show the entire string, name, or phrase that the ellipsis is representing. The only time a browser tooltip does not need to be used is at the end of a truncated paragraph.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.891304347826086%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAABqElEQVQoz2NgIBL4+fkxREREMISFhYH5MJoqgCTD5DVMwbScmjGzkpaFlKKmmby6vrVIVnoSS3BICBvQMCaSDJ3qDaE3RTAwttkzCLTbMAgtC2XgSk7PFcxITxWsra1lA8mzsrIyfP78mbCBbGn/IYzI//xSRf97pYv/L2VI/B+Cru7///9Ehs/U32A6ZNp/noip3wpCJn5pyJjzPvzPx3uKr99+UH/58qXyhw8fwK58+Ogx4+27dxnOXbgA1nPtxg1MA////cgIccFfkR+fnh/+/uHJxfevn1x++erV/S9fvjx8//79FaBXZdD13X/4ELsL33/4DDbwz7//wk+evdh09dqNc/fuP7zw9OnTM/fv3z//6tWrI1+/ftV6/vIV54ePnwTfvn8vcOPmLRaQnidPn2E3VF9fn+HEyZNgg3t6e6WPHjs2BYgXHDt+fC5QfN7pM2fmA+n5QP48IL30xMlTOiC1p06fZsRqICzAYbSbmxuzi4sLm4+PD6uLqwsYOzg4sFpZWbE6OjqyRkVFgQ3y9/fHNAyWFIBhBTawqqqKqMgsKysD0wAOAsuT3+/yqwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of a browser tooltip being used for truncation.",
            "title": "Example of a browser tooltip being used for truncation.",
            "src": "/static/359a9bb40dd83627070b84db4c3617ca/fb070/BrowserTooltip.png",
            "srcSet": ["/static/359a9bb40dd83627070b84db4c3617ca/d6747/BrowserTooltip.png 288w", "/static/359a9bb40dd83627070b84db4c3617ca/09548/BrowserTooltip.png 576w", "/static/359a9bb40dd83627070b84db4c3617ca/fb070/BrowserTooltip.png 1152w", "/static/359a9bb40dd83627070b84db4c3617ca/c3e47/BrowserTooltip.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">Example of a browser tooltip being used for truncation.</Caption>
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "38.04347826086957%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsSAAALEgHS3X78AAAAQElEQVQoz2NgGMqAG4iFgFgQCYP4rOQaKAnE6kCsCsXKUJqfEgNBhigBsQwQqwCxItTlZHtZGIg5oHwmKB7iAAD69wI9oYAUvwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of end-line truncation for a paragraph.",
            "title": "Example of end-line truncation for a paragraph.",
            "src": "/static/166f444393d957bb8789c9dc3238e46a/fb070/Truncated-Paragraph.png",
            "srcSet": ["/static/166f444393d957bb8789c9dc3238e46a/d6747/Truncated-Paragraph.png 288w", "/static/166f444393d957bb8789c9dc3238e46a/09548/Truncated-Paragraph.png 576w", "/static/166f444393d957bb8789c9dc3238e46a/fb070/Truncated-Paragraph.png 1152w", "/static/166f444393d957bb8789c9dc3238e46a/c3e47/Truncated-Paragraph.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">Example of end-line truncation for a paragraph.</Caption>
      </Column>
    </Row>
    <h3 {...{
      "id": "usage"
    }}>{`Usage`}</h3>
    <p>{`Good use cases for truncation include:`}</p>
    <ul>
      <li parentName="ul">{`Breadcrumbs`}</li>
      <li parentName="ul">{`Pagination`}</li>
      <li parentName="ul">{`Long URL links`}</li>
      <li parentName="ul">{`Paragraph of text (i.e. a description paragraph)`}</li>
      <li parentName="ul">{`Shortening of a long item name (user- or platform-generated)`}</li>
    </ul>
    <p>{`Truncation should `}<strong parentName="p">{`not`}</strong>{` be used on page headers, titles, labels, error messages, validation messages, notifications, menu items, or tabs.`}</p>
    <h3 {...{
      "id": "variations"
    }}>{`Variations`}</h3>
    <p>{`There are three types of truncation: front-line, mid-line, and end-line.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Purpose`}</th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Truncated`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Front-line`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Used at the beginning of a text string to indicate the text is continued from a previous location.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`123456789`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`...56789`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Mid-line`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Used when several text strings have different beginnings and/or endings but the exact same middle characters. Can also be used to shorten a phrase or text string when the end of a string cannot be truncated by an ellipsis.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`123400005678`}</inlineCode>{` `}<inlineCode parentName="td">{`987600004321`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`1234...5678`}</inlineCode>{` `}<inlineCode parentName="td">{`9876...4321`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`End-line`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Used at the end of a character string or paragraph to indicate that there is more content in another location, to show that the pattern in a sequence continues, or to shorten a long text string.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`123456789`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`12345...`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <br />
    <h4 {...{
      "id": "ellipses-alone"
    }}>{`Ellipses alone`}</h4>
    <p>{`An ellipsis on its own may also represent condensed content. This type of truncation requires an overflow menu on hover instead of a browser tooltip.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "34.10326086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsSAAALEgHS3X78AAABBUlEQVQoz2NgQAZJ/8FU6NTfDAz+3xj+///PcPzMVcabt24zXn34hfHR8/eM379+Ybzx6BPjgYtvwWpn7f3CgB0k/0cxlBiw6dRHPHpAgkn/wEzG5H+8QL4QQ9xv7hXrd8pMWXNRNXXRf9myZZ9kulbfl8iY/11Rt+anMIPLf2bcBib+gxuYOf8rWOGztz/EHj1+/PDBg4f/nj1/+end+w9fPn789PH9h09/Hz977QZS8/Xzeyb8vgJK/v9/E8x89+al0OPHj/c8ePDgypMnjy+8ffvm4rt3786/e/f2xovnz6xAal6+es2Ew6B/SE7fwrDzKeGwBEUaUeEOUwii8WEUQ6EAACouqlVRY/WjAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of a truncated Breadcrumb utilizing an ellipse with an Overflow Menu.",
            "title": "Example of a truncated Breadcrumb utilizing an ellipse with an Overflow Menu.",
            "src": "/static/4596bb0a3990695be6aea96c047874b4/fb070/Ellipse.png",
            "srcSet": ["/static/4596bb0a3990695be6aea96c047874b4/d6747/Ellipse.png 288w", "/static/4596bb0a3990695be6aea96c047874b4/09548/Ellipse.png 576w", "/static/4596bb0a3990695be6aea96c047874b4/fb070/Ellipse.png 1152w", "/static/4596bb0a3990695be6aea96c047874b4/c3e47/Ellipse.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">
  Example of a truncated breadcrumb utilizing an ellipsis with an overflow menu.
        </Caption>
      </Column>
    </Row>
    <h3 {...{
      "id": "code"
    }}>{`Code`}</h3>
    <p>{`To use front- and end-line truncation, enter the appropriate class below and add `}<inlineCode parentName="p">{`title`}</inlineCode>{` to populate the browser tooltip that appears when truncated text is hovered. The `}<inlineCode parentName="p">{`width`}</inlineCode>{` of the container (or the text element itself) also needs to be configured in order to calculate where the truncation will start.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Frontline`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--text-truncate--front`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`End-line`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--text-truncate--end`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <br />
    <h4 {...{
      "id": "example-usage"
    }}>{`Example Usage`}</h4>
    <br />
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="container">
  <span title="123456789" class="bx--front-line">123456789</span>
</div>
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.container {
  width: 65px;
}
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.bx--front-line {
  width: 100%;
  display: inline-block;
  direction: rtl;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
`}</code></pre>
    <br />
    <h4 {...{
      "id": "result"
    }}>{`Result`}</h4>
    <br />
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "24.456521739130437%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAAsSAAALEgHS3X78AAAAG0lEQVQY02NgGCDASE3DWIFYgNqGMg5U0DAAABStABlBrxFYAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Result.",
            "title": "Result.",
            "src": "/static/bb052f622269527a0bda39c4bb3e6096/fb070/1c695894-538c-11e8-8cd2-bb0b1cac151b.png",
            "srcSet": ["/static/bb052f622269527a0bda39c4bb3e6096/d6747/1c695894-538c-11e8-8cd2-bb0b1cac151b.png 288w", "/static/bb052f622269527a0bda39c4bb3e6096/09548/1c695894-538c-11e8-8cd2-bb0b1cac151b.png 576w", "/static/bb052f622269527a0bda39c4bb3e6096/fb070/1c695894-538c-11e8-8cd2-bb0b1cac151b.png 1152w", "/static/bb052f622269527a0bda39c4bb3e6096/c3e47/1c695894-538c-11e8-8cd2-bb0b1cac151b.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>
    <br />
    <h4 {...{
      "id": "mid-line-truncation"
    }}>{`Mid-line truncation`}</h4>
    <p>{`Mid-line truncation does not have its own class as it requires JavaScript. This example in CodePen shows how it is implemented.`}</p>
    <Row mdxType="Row">
  <Column colLg={8} mdxType="Column">
    <iframe height="300" scrolling="no" title="Middle Truncation" src="//codepen.io/team/carbon/embed/KRoBQe/?height=300&theme-id=30962&default-tab=result&embed-version=2" frameBorder="no" allowtransparency="true" allowFullScreen="true" style={{
          "width": "100%"
        }}>
      See the Pen{' '}
      <a href="https://codepen.io/team/carbon/pen/KRoBQe/">Middle Truncation</a>{' '}
      by Carbon Design System (<a href="https://codepen.io/carbon">@carbon</a>)
      on <a href="https://codepen.io">CodePen</a>.
    </iframe>
  </Column>
    </Row>
    <br />
    <h3 {...{
      "id": "show-more-buttons"
    }}>{`‘Show more’ buttons`}</h3>
    <p>{`The ‘Show more’ button is used when there is a significant amount of overflow content. Implementing a ‘Show more’ button gives a user the ability to see the content in more digestible chunks, as opposed to all at once. A ‘Show more’ button is used in place of scrolling, gradients, or fades as they are more prominent and actionable. If needed, a ‘Show less’ can be used to again hide the content the user opened. ‘Show more’ can also be presented as ‘Load more’ in cases where performance is a concern. See the `}<a parentName="p" {...{
        "href": "/components/loading"
      }}>{`Loading`}</a>{` section for additional details.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <img {...{
          "src": "/5eedf92798d12e330f5ed78cf9611e7a/show-more.gif",
          "alt": "Example of a Code Snippet utilizing the 'Show more' Button."
        }}></img>
        <Caption mdxType="Caption">Example of the 'Show more' button in context.</Caption>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      